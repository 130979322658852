import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import "../styles/Login.css";

export default function ResetPassword() {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/reset-password/${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPassword }),
      });
      const data = await response.json();
      setMessage(data.message);
      if (data.message === 'Invalid or expired token') {
        Swal.fire({
          title: 'Reset Expired',
          text: `Password reset epired.`,
          icon: 'error',
          allowOutsideClick: false,
          confirmButtonText: 'Ok',
        });
      } else {
        Swal.fire({
          title: 'Password Reset',
          text: `Your password was reset successfully.`,
          icon: 'info',
          allowOutsideClick: false,
          confirmButtonText: 'Ok',
        });
      }
    } catch (error) {
      setMessage('An error occurred while resetting the password');
    }
  };

  return (
    <div className="login-container">
            <div className='login_box'>
                <div className='login-header'>
                    <span>Reset Password</span>
                </div>
                <div className='input_box'>
                    <input type={showPassword ?  "text" : "password"} className='input-field' id='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required  />
                    <label for='password' className='label'>New Password</label>
                    <i className="icon">
                    <ion-icon  name="mail"></ion-icon>
                    </i>
                </div>
                <div>
                    <Button onClick={togglePasswordVisibility}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                </div>
                <div>
                    <Button 
                        className='login-button mt-3' 
                        onClick={handleResetPassword}
                        id="submitBtn"
                        type="submit"
                        variant="danger"
                    >Reset Password
                    </Button>
                </div>
                {message && <p>{message}</p>}
            </div>
        </div>
  );
}
