import React from 'react';
import ProductCard from '../components/ProductCard';
import Loading from '../components/Loading'
import { useEffect, useState } from 'react'

export default function Products(){
	const [products, setProducts] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/product/`)
		.then(response => response.json())
		.then(result => {
			console.log(result)
			setProducts(
				result.map(product => {
					return (
						<ProductCard key={product._id} productProp ={product}/>
					)
				})
			)
			setIsLoading(false)
		})
	}, [])

	return(			
		(isLoading) ?
			<Loading/>
		:
		<>
			<h3 className="pt-3 pb-3 text-center">Buy Now</h3>
			<div className='cardChicken mb-3'>
				{products}
			</div>
				
		</>
	)
}
