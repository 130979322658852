import React, { useState, useEffect, useContext } from 'react';
import {Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import UserContext from '../UserContext';

export default function AdminCard({agent}){

  const {user} = useContext(UserContext);
  const [counts, setCounts] = useState({ agentCount: 0, playerCount: 0 });

  useEffect(() => {
    if (agent) {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/subagent/${agent._id}/count`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCounts(data);
            } catch (error) {
                console.error('Error fetching profiles:', error);
            } 
        };
        fetchData();
    }
  }, [agent]);

  return(
    <>
    <h5 className='text-primary'>{agent.username}</h5>
    <Card className='mb-3'>
      <Card.Body>
        <Card.Title className='text-primary'>Name: {agent.firstName} {agent.lastName}</Card.Title>
        <Card.Text>Email: {agent.email}</Card.Text>
        <Card.Text>Mobile No: {agent.mobileNo}</Card.Text>
        <Card.Text>Loader Level: {agent.subAgentCategory}</Card.Text>
        <Card.Text>Diamonds for Distribution: {agent.agentdiamond}</Card.Text>
        <Card.Text>Total No. of Loader/s - All under: {counts.agentCount}</Card.Text>
        <Card.Text>Total No. of Player/s - All under: {counts.playerCount}</Card.Text>
        <Card.Text>Commission Earned: {agent.earnagentdiamond}</Card.Text>
        {(!user.isAdmin) ? (
          <Link className="btn btn-primary" to={`/agent/${user.id}/${agent._id}`}>Transfer Diamonds to Loader</Link>
        ) : (
          <></>
        )}
      </Card.Body>
    </Card>
    </>
  )
}
