import React, { useState, useEffect, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import UserContext from '../UserContext';
import Swal from 'sweetalert2';

export default function Helpdesk() {
	const {user} = useContext(UserContext)
	const [isActive, setIsActive] = useState(false)
	const [description, setDescription] = useState('')
    const [submitted, setSubmitted] = useState(false);

	useEffect(() => {
        if((description !== '')){
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [description]);

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Create email content
        const messageData = {
            email: user.email || 'anonymous',
            subject: 'Helpdesk Inquiry',
            message: description,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/helpdesk/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(messageData),
            });

            if (response.ok) {
                setSubmitted(true);
                setDescription('');
                Swal.fire({
                    title: 'Email Sent',
                    text: `Your concern has been submitted.`,
                    icon: 'info',
                    allowOutsideClick: false,
                    confirmButtonText: 'Ok',
                });
            } else {
                alert('There was an issue sending your message. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again later.');
        }
    };


    return (
    <>
        <div rows="4" cols="50">
            <h4 className="profile-name p-3 text-primary text-center">How can we help you today?</h4>
			<Form onSubmit={handleSubmit}>
                <Form.Group controlId="description">
					<Form.Text className="text-muted">
                        Type your concerns here.
                    </Form.Text>
                    <Form.Control 
                        as="textarea"
                        rows={4}
                        value={description}
						onChange={event => setDescription(event.target.value)}
                        required
                    /> 
                </Form.Group>
                <br/>
                <div>
                    <Button 
                        className='mt-3' 
                        onClick={event => handleSubmit(event)}
                        id="submitBtn"
                        type="submit"
                        variant={(isActive) ? "primary" : "danger"}
                        color={(isActive) ? "#1f2937" : "#ee0050"}
                        disabled={!isActive}
                    > Submit Concern
                    </Button>
                </div>
			</Form>	
		<p className='text-primary'>Go back to the <Link to= {"/"} >homepage.</Link></p>
        </div>
    </>
    )
}