import React from 'react';
import './App.css';
import { useState, useEffect } from 'react';
import { UserProvider } from './UserContext';

import AppNavbar from './components/AppNavbar';
import Home from './pages/Home';
//Admin
import AdminDashboard from './pages/AdminDashboard';
import SuperAdminDashboard from './pages/SuperAdminDashboard';
//Agent
import AgentDashboard from './pages/AgentDashboard';
import PlayerDiaTransferView from './components/PlayerDiaTransferView';
import AgentDiaTransferView from './components/AgentDiaTransferView';
import PlayerToAgentView from './components/PlayerToAgentView';
import SetAgentDiamond from './components/SetAgentDiamond';
import SetReferralLink from './components/SetReferralLink';

import LoaderDashboard from './components/LoaderDashboard';

//Products
import Products from './pages/Products';
import ProductView from './components/ProductView';
// import CreateProduct from './pages/CreateProduct';
import ProductDetail from './components/ProductDetail';

//Shopping Mall
import ProductList from './components/ProductList';
import CreateMallProduct from './pages/CreateMallProduct';
import MallProductDetail from './components/MallProductDetail';
import MerchantDashboard from './pages/MerchantDashboard';
import MerchantProducts from './pages/MerchantProducts';

// Order and Checkout
import OrderConfirmation from './components/OrderConfirmation';
import Checkout from './components/Checkout';

//Diamond
import Diamonds from './pages/Diamonds';
import DiamondView from './components/DiamondView';
import RequestAgentDiaView from './components/RequestAgentDiaView';
import SellDiamondView from './components/SellDiamondView';
import SellAgentDiaView from './components/SellAgentDiaView';
import UpdateDiamond from './pages/UpdateDiamond';

//-----Transactions
//Sell
import SellPlayerTransactionView from './components/SellPlayerTransactionView';
import AgentTransactionView from './components/AgentTransactionView';
//Buy
import BuyAgentTransactionView from './components/BuyAgentTransactionView';
import TransactionView from './components/TransactionView';
//History
import TransactionHistory from './pages/TransactionHistory';
import AdminTransactionHistory from './pages/AdminTransactionHistory';
//Ads
import AdsDashboard from './pages/AdsDashboard';
import CreateAds from './pages/CreateAds';

//Liquidations
import Liquidation from './pages/Liquidation';

//Users
import Register from './pages/Register';
import Login from './pages/Login';
import Logout from './pages/Logout';
import UpdateUser from './pages/UpdateUser';
import ChangeLoader from './pages/ChangeLoader';
import AgentChangeLoader from './pages/AgentChangeLoader';
import ErrorPage from './pages/ErrorPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Profiles from './pages/Profiles';
import Chickens from './pages/Chickens';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

import Matches from './pages/Matches';
import Prediction from './pages/Prediction';
import MatchPredict from './components/MatchPredict';
import PlaceBet from './components/PlaceBet';

import Helpdesk from './pages/Helpdesk';
import PreFooter from './components/PreFooter';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {

  const [user, setUser] = useState({
          id: null,
          googleId: null,
          facebookId: null,
          gameId: null,
          username: null,
          resetPasswordToken: null,
          resetPasswordExpires: null,
          //
          firstName: null,
          lastName: null,
          currentBet: null,
          elo: null,
          //
          email: null,
          mobileNo: null,
          fbMessenger: null,
          viberNo: null,
          whatsappNo: null,
          avatar: null,
          cityTown: null,
          gold: null,
          diamond: null,
          agentdiamond: null,
          agentpercentage: null,
          earnagentdiamond: null,
          setagentdiamond: null,
          earndiamond: null,
          playerlevel: null,
          levelplus: null,
          chickencount: null,
          exp: null,
          win: null,
          loss: null,
          //
          isAdmin: null,
          isMerchant: null,
          isSuperAdmin: null,
          isAgent: null,
          isSubAgent: null,
          subAgentCategory: null,
          isActive: null,
          isOnline: null,
          //
          chickens: null,
          items: null,

          agent: null,
          referral_code: null,
          parent_referral_code: null
  })

  const unsetUser = () => {
    localStorage.clear();
    setUser({
          id: null,
          googleId: null,
          facebookId: null,
          gameId: null,
          username: null,
          resetPasswordToken: null,
          resetPasswordExpires: null,
          //
          firstName: null,
          lastName: null,
          currentBet: null,
          elo: null,
          //
          email: null,
          mobileNo: null,
          fbMessenger: null,
          viberNo: null,
          whatsappNo: null,
          avatar: null,
          cityTown: null,
          gold: null,
          diamond: null,
          agentdiamond: null,
          agentpercentage: null,
          earnagentdiamond: null,
          setagentdiamond: null,
          earndiamond: null,
          playerlevel: null,
          levelplus: null,
          chickencount: null,
          exp: null,
          win: null,
          loss: null,
          //
          isMerchant: null,
          isAdmin: null,
          isSuperAdmin: null,
          isAgent: null,
          isSubAgent: null,
          subAgentCategory: null,
          isActive: null,
          isOnline: null,
          //
          chickens: null,
          items: null,

          agent: null,
          referral_code: null,
          parent_referral_code: null
    })
  }

  useEffect(() => {

      fetch(`${ process.env.REACT_APP_API_URL }/users/getDetail`, {
        headers: {
          Authorization: `Bearer ${ localStorage.getItem('token') }`
        }
      })
      .then(res => res.json())
      .then(data => {
        if (typeof data._id !== "undefined") {
          setUser({
            id: data._id,
            googleId: data.googleId,
            facebookId: data.facebookId,
            gameId: data.gameId,
            username: data.username,
            resetPasswordToken: data.resetPasswordToken,
            resetPasswordExpires: data.resetPasswordExpires,

            firstName: data.firstName,
            lastName: data.lastName,
            currentBet: data.currentBet,
            elo: data.elo,

            email: data.email,
            mobileNo: data.mobileNo,
            fbMessenger: data.fbMessenger,
            viberNo: data.viberNo,
            whatsappNo: data.whatsappNo,
            cityTown: data.cityTown,
            avatar: data.avatar,
            gold: data.gold,
            diamond: data.diamond,
            agentdiamond: data.agentdiamond,
            agentpercentage: data.agentpercentage,
            earnagentdiamond: data.earnagentdiamond,
            setagentdiamond: data.setagentdiamond,
            earndiamond:  data.earndiamond,
            playerlevel: data.playerlevel,
            levelplus:  data.levelplus,
            chickencount: data.chickencount,
            exp: data.exp,
            win: data.win,
            loss: data.loss,
            isAdmin: data.isAdmin,

            isMerchant: data.isMerchant,
            isSuperAdmin: data.isSuperAdmin,
            isAgent: data.isAgent,
            isSubAgent: data.isSubAgent,
            subAgentCategory: data.subAgentCategory,
            isActive: data.isActive,
            isOnline: data.isOnline,

            chickens: data.chickens,
            items: data.items,

            agent: data.agent,
            referral_code: data.referral_code,
            parent_referral_code: data.parent_referral_code
          });
        } else {
          setUser({
            id: null,
            googleId: null,
            facebookId: null,
            gameId: null,
            username: null,
            resetPasswordToken: null,
            resetPasswordExpires: null,
            //
            firstName: null,
            lastName: null,
            currentBet: null,
            elo: null,
            //
            email: null,
            mobileNo: null,
            fbMessenger: null,
            viberNo: null,
            whatsappNo: null,
            avatar: null,
            cityTown: null,
            gold: null,
            diamond: null,
            agentdiamond: null,
            agentpercentage: null,
            earnagentdiamond: null,
            setagentdiamond: null,
            earndiamond: null,
            playerlevel: null,
            levelplus: null,
            chickencount: null,
            exp: null,
            win: null,
            loss: null,
            //
            isAdmin: null,
            isMerchant: null,
            isSuperAdmin: null,
            isAgent: null,
            isSubAgent: null,
            subAgentCategory: null,
            isActive: null,
            isOnline: null,
            //
            chickens: null,
            items: null,

            agent: null,
            referral_code: null,
            parent_referral_code: null
          });
        }
      })
  }, []);

  return (
    <>
      {/* <div className={`app-container ${theme}`}> */}
      <div className="background-image">
      <UserProvider value={{user, setUser, unsetUser}}>
        <Router>
          <AppNavbar/>
          <Container>
            <Routes>
              <Route path= "/" element={<Home />} />
              <Route path= "/login" element={<Login/>}/>
              <Route path= "/register/:agentId?" element={<Register/>}/>
              <Route path= "/forgot-password" element={<ForgotPassword/>}/>
              <Route path= "/reset-password/:token?" element={<ResetPassword/>}/>
              <Route path= "/predict" element={<Prediction/>}/>
              <Route path= "/predict/:profileId" element={<MatchPredict/>}/>
              {/*--- FOF Items & Chicken ---*/}
              <Route path= "/product" element={<Products />} />
              {/* <Route path= "/product/create" element={<CreateProduct />} /> */}
              <Route path= "/product/:productId" element={<ProductView />} />
              
              {/*--- Shopping Mall ---*/}
              <Route path= "/shoppingmall" element={<ProductList />} />
              <Route path= "/shoppingmall/create" element={<CreateMallProduct />} />
              <Route path= "/shoppingmall/:productId" element={<MallProductDetail />} />
              <Route path= "/merchant" element={<MerchantDashboard />} />
              <Route path= "/merchantproduct" element={<MerchantProducts />} />
              {/* Order and Checkout routes */}
              <Route path= "/order" element={<OrderConfirmation />} />
              <Route path= "/checkout" element={<Checkout />} />

              <Route path= "/chicken" element={<Chickens/>}/>
              <Route path= "/profile" element={<Profiles/>}/>
              <Route path= "/editprofile" element={<UpdateUser/>}/>
              <Route path= "/changeloader" element={<ChangeLoader/>}/>
              <Route path= "/agentchangeloader" element={<AgentChangeLoader/>}/>
              <Route path= "/changerequest" element={<LoaderDashboard/>}/>
              <Route path= "/editdiamond" element={<UpdateDiamond/>}/>
              <Route path= "/player/:agentId/:profileId" element={<PlayerDiaTransferView/>}/>
              <Route path= "/playertoagent/:agentId/:profileId" element={<PlayerToAgentView/>}/>
              
              <Route path= "/agent/:agentId" element={<SetAgentDiamond/>}/>
              <Route path= "/agent/:agentId/:profileId" element={<AgentDiaTransferView/>}/>
              <Route path= "/profile/:profileId" element={<PlaceBet/>}/>
              
              <Route path= "/diamond" element={<Diamonds/>}/>
              {/* //BUY Diamonds Player to Agent */}
              <Route path= "/buy-diamond/:diamondId/:profileId" element={<DiamondView/>}/>
              {/* //BUY Diamonds Agent to Agent */}
              <Route path= "/request-agentdiamond" element={<RequestAgentDiaView/>}/>
              {/* //SELL Diamonds Player to Agent */}
              <Route path= "/sell-diamond/:diamondId/:profileId" element={<SellDiamondView/>}/>
              {/* //SELL Diamonds Agent to Agent */}
              <Route path= "/sell-agentdiamond" element={<SellAgentDiaView/>}/>

              <Route path= "/setreferrallink/:profileId" element={<SetReferralLink/>}/>
              {/* //TRANSACTION */}
              {/* //Buy */}
              <Route path= "/transaction/:transactionId/:profileId" element={<TransactionView/>}/>
              <Route path= "/transaction/buydiamondagent/:transactionId/:profileId" element={<BuyAgentTransactionView/>}/>
              {/* //Sell */}
              <Route path= "/transaction/playertransaction/:transactionId/:profileId" element={<SellPlayerTransactionView/>}/>
              <Route path= "/transaction/agenttransaction/:transactionId/:profileId" element={<AgentTransactionView/>}/>

              <Route path= "/transactionhistory" element={<TransactionHistory/>}/>
              <Route path= "/admintransactionhistory" element={<AdminTransactionHistory/>}/>

              <Route path= "/admin" element={<AdminDashboard/>}/>
              <Route path= "/superadmin" element={<SuperAdminDashboard/>}/>
              <Route path= "/agent" element={<AgentDashboard/>}/>
              
              <Route path= "/liquidation" element={<Liquidation/>}/>

              <Route path= "/logout" element={<Logout/>} />
              <Route path= "/match" element={<Matches/>} />
              <Route path= "/history" element={<Matches/>} />

              <Route path= "/createads" element={<CreateAds/>} />
              <Route path= "/adsdashboard" element={<AdsDashboard/>}/>

              <Route path= "/helpdesk" element={<Helpdesk/>}/>  
              <Route path= "/*" element={<ErrorPage/>}/>
              <Route path= "/privacypolicy" element={<PrivacyPolicy/>}/>
            </Routes>
          </Container>
          <PreFooter/>
        </Router>
      </UserProvider>
      </div>
    </>
  );
}

export default App;
