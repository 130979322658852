import React from "react";
import { Navigate } from "react-router-dom";
import UserContext from '../UserContext';
import { useContext, useEffect } from 'react';

export default function Logout() {
    const { unsetUser, setUser } = useContext(UserContext);

    unsetUser(localStorage.clear());
    
    useEffect(() => {
        setUser({
            id: null,
            username: null,
            email: null,
            gold: null,
            diamond: null,
            playerlevel: null,
            exp: null,
            isAdmin: null
        })
    })
    return (
        <Navigate to= {"/login"} />
    )
};