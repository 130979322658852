import React, { useState, useEffect } from 'react';
import '../styles/Room.css';
import Swal from 'sweetalert2';

export default function AdsCard({ ad }) {
    const [timeRemaining, setTimeRemaining] = useState('');
    const [isAdsClosed, setIsAdsClosed] = useState(false);

    const updateRemainingTime = () => {
        if (ad.expiration) {
            const now = new Date();
            const endTime = new Date(ad.expiration);
            const timeDiff = endTime - now;

            if (timeDiff <= 0) {
                setTimeRemaining('Ads Expired');
                setIsAdsClosed(true); // Set state if ad has expired
            } else {
                const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
                const seconds = Math.floor((timeDiff / 1000) % 60);
                setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s remaining`);
            }
        }
    };

    const handleImageClick = (ad) => {
        if (ad.video) {
            Swal.fire({
                title: `${ad.name}`,
                html: `<video width="100%" controls autoplay>
                        <source src="${ad.video}" type="video/mp4">
                        Your browser does not support the video tag.
                       </video>`,
                confirmButtonText: 'OK',
                allowOutsideClick: false,
            });
        } else if (ad.image) {
            Swal.fire({
                title: `${ad.name}`,
                imageUrl: `${ad.image}`,
                text: `${ad.name}`,
                confirmButtonText: 'OK',
                allowOutsideClick: false,
            });
        }
    };


    useEffect(() => {
        const interval = setInterval(updateRemainingTime, 1000);
        return () => clearInterval(interval); // Cleanup on component unmount
    }, [ad]); // Only rerun if the `ad` prop changes

    return (
        <div className="table-container">
            <div key={ad._id} className="table-item" >
                <div className="video-preview" onClick={() => handleImageClick(ad)}>
                    <img
                        width="100%"
                        height="150px"
                        src={ad.image}
                        title={ad.description}
                        alt={ad.name}
                    />
                </div>
                <div className="table-info">
                    <h5 className='text-center'><span style={{color: 'blue'}}>{ad.name}</span> &nbsp;&nbsp; <span> { ad.isFeatured ? <p style={{fontSize: 20}}> <ion-icon name="ribbon" style={{ color: 'red' }}></ion-icon> Featured </p> : <></> } </span> </h5>
                    <p className='text-center'>Amount: Php {ad.amount}</p>
                    <p className='text-center'>Expiration Time Left: <span style={{color: 'red'}}>{timeRemaining}</span></p>
                </div>
            </div>
        </div>
    );
};