import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'react-bootstrap';
import UserContext from '../UserContext';
import HomeDiamondCard from '../components/HomeDiamondCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import '../App.css';

export default function ChangeLoader() {
    const { user } = useContext(UserContext);
    const [diamonds, setDiamonds] = useState([]);
    const [allDiamonds, setAllDiamonds] = useState([]);
    const [groupedDiamonds, setGroupedDiamonds] = useState({});
    const [groupedAllDiamonds, setGroupedAllDiamonds] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [searchMessage, setSearchMessage] = useState('');

    useEffect(() => {
        fetchDiamonds();
        fetchAllDiamonds();
    }, []);

    const fetchDiamonds = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/diamond/getAllLoadingStation`, {
                method: 'GET',
            });
            const data = await response.json();
            setDiamonds(data);
        } catch (error) {
            console.error('Error fetching diamonds:', error);
        }
    };

    const fetchAllDiamonds = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/diamond/getAllLoaders`, {
                method: 'GET',
            });
            const data = await response.json();
            setAllDiamonds(data);
        } catch (error) {
            console.error('Error fetching diamonds:', error);
        }
    };

    const groupByLocation = (diamonds) => {
        return diamonds.reduce((acc, diamond) => {
            const location = diamond.diamond_location || 'Unknown Location';
            if (!acc[location]) acc[location] = [];
            acc[location].push(diamond);
            return acc;
        }, {});
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearch = async () => {
        try {
            if (window.innerWidth < 599) {
                if (window.screen.orientation && window.screen.orientation.lock) {
                    await window.screen.orientation.lock('landscape');
                } else {
                    showAlert('Please tilt your device in choosing loaders.');
                }
            }
            filterResults();
        } catch (error) {
            showAlert('Please tilt or rotate your device.');
        }
    };

    const showAlert = (message) => {
        Swal.fire({
            icon: 'info',
            title: 'Notice',
            text: message,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
        });
    };

    const filterResults = () => {
        const searchTermLower = searchTerm.toLowerCase();
        const filteredDiamonds = diamonds.filter((diamond) =>
            Object.values(diamond).some((value) =>
                typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
            )
        );

        const filteredAllDiamonds = allDiamonds.filter((diamond) =>
            Object.values(diamond).some((value) =>
                typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
            )
        );

        if (filteredDiamonds.length === 0 && filteredAllDiamonds.length === 0) {
            setSearchMessage('No results found.');
        } else {
            setSearchMessage('');
        }

        setGroupedDiamonds(groupByLocation(filteredDiamonds));
        setGroupedAllDiamonds(groupByLocation(filteredAllDiamonds));
    };

    const renderDiamondGroups = (groupedData) => {
        return Object.entries(groupedData).map(([location, diamonds]) => (
            <div key={location}>
                <h6 className='text-danger'>{location}</h6>
                <div className="cardChicken mb-2">
                    {diamonds.map((diamond) => (
                        <HomeDiamondCard key={diamond._id} diamond={diamond} />
                    ))}
                </div>
            </div>
        ));
    };

    return (
        user.id === null ? (
            <div className="mb-5 pb-5">
                <h5 className="profile-list pt-3 pb-3">Choose your Loader or search below:</h5>
                <span>
                    <p className="pt-3 pb-3">
                        If you don't have a loading station, click or tap the search icon &nbsp;
                        <button className="btn btn-light" onClick={handleSearch}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </p>
                </span>
                <p className="profile-list pt-3">
                    If you know the name of your loading station, search the name at the searchbar then click or tap the Search button.
                </p>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search by name or keyword, etc..."
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
                    &nbsp;
                    <Button onClick={handleSearch}>Search</Button>
                    <p>{searchMessage}</p>
                </div>

                <h6>Loading Stations</h6>
                {renderDiamondGroups(groupedDiamonds)}

                <h6>Loaders</h6>
                {renderDiamondGroups(groupedAllDiamonds)}
            </div>
        ) : (
            <></>
        )
    );
}
