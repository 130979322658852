import React from 'react';
import {Card, CardImg, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import UserContext from '../UserContext';
import {useContext} from 'react';

export default function DiamondCard({diamond}){

  const {user} = useContext(UserContext)

  return(
    <div className= 'cardSize'>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <p style={{fontSize: 10}}><strong> {diamond.name}</strong></p>
              <p style={{fontSize: 10}}><strong>Mobile:</strong> {diamond.mobileNo}</p>
              <p style={{fontSize: 10}}><strong>FB:</strong> {diamond.fbMessenger}</p>
              <p style={{fontSize: 10}}><strong>Viber:</strong> {diamond.viberNo}</p>
              <p style={{fontSize: 10}}><strong>WhatsApp:</strong> {diamond.whatsappNo}</p>
              <p style={{fontSize: 10}}><strong>On Stock:</strong> {(diamond.onStock ? "Yes" : "No")}</p>
              {(user.isAgent === false) ?
                <Link className="btn btn-primary" style={{fontSize: 10}} to={`/buy-diamond/${diamond._id}/${user.id}`}>Buy Now</Link>
                :
                <></>
              }
              <br/>
              <br/>
              <Link className="btn btn-danger" style={{fontSize: 10}} to={`/sell-diamond/${diamond._id}/${user.id}`}>Trade</Link>
              <CardImg width="25%" height="25%" top src={diamond.imageUrl}/>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
