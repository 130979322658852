import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {Form, Button} from 'react-bootstrap';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';

export default function UpdateUser() {
	const navigate = useNavigate();
	const [fbMessenger, setFbMessenger] = useState('');
	const [viberNo, setViberNo] = useState('');
	const [whatsappNo, setWhatsappNo] = useState('');
	const [cityTown, setCityTown] = useState('');
	const [mobileNo, setMobileNo] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [avatar, setAvatar] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const avatars = [
		'/avatar/avatar1.png',
		'/avatar/avatar2.png',
		'/avatar/avatar3.png',
		'/avatar/avatar4.png',
		'/avatar/avatar5.png',
		'/avatar/avatar6.png',
		'/avatar/avatar7.png',
		'/avatar/avatar8.png',
		'/avatar/avatar9.png',
		// Add more avatar images here
	];

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleAvatarSelection = (selectedAvatar) => {
		setAvatar(selectedAvatar);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/users/updateUser`, {
				method: 'POST',
				headers: {
					'Content-Type' : 'application/json',
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
				body: JSON.stringify({
					fbMessenger: fbMessenger,
					viberNo: viberNo,
					whatsappNo: whatsappNo,
					cityTown: cityTown,
					mobileNo: mobileNo,
					email:email,
					password: password,
					avatar: avatar
				})
			});

			const data = await response.json();
			if (response.ok) {
				console.log(data.message);
				// Show success message
				Swal.fire({
					title: 'User profile updated successfully!',
					icon: 'success',
					text: data.message
				});
				navigate('/profile');
				// Optionally, redirect the user to another page after successful registration
			} else {
				console.error('Error updating user:', data.message);
				if (data.message === 'Email already exists') {
					// Show alert for duplicate email
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'Email already exists in the database. Please use a different email.'
					});
				} else {
					// Show error message
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'An error occurred. Please try again later.'
					});
				}
			}
		} catch (error) {
			console.error('Error registering user:', error.message);
			// Show error message
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Something went wrong!'
			});
		}
	};

	return (
		<>
		<h5>Update Profile</h5>
			<Form onSubmit={e => handleSubmit(e)}>
				<Form.Group controlId="fbMessenger">
			        <Form.Label className='text-primary'>Facebook Messenger</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change or Add Facebook Messenger"
							name="fbMessenger"
				            value={fbMessenger}
				            onChange={e => setFbMessenger(e.target.value)}
				            // required
				        />
			    </Form.Group>

				<Form.Group controlId="viberNo">
			        <Form.Label className='text-primary'>Viber Number</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change or Add Viber Number"
							name="viberNo"
				            value={viberNo} 
				            onChange={e => setViberNo(e.target.value)}
				            // required
				        />
			    </Form.Group>

				<Form.Group controlId="whatsappNo">
			        <Form.Label className='text-primary'>WhatsApp Number</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change or Add WhatsApp Number"
							name="whatsappNo"
				            value={whatsappNo} 
				            onChange={e => setWhatsappNo(e.target.value)}
				            // required
				        />
			    </Form.Group>

				<Form.Group controlId="cityTown">
			        <Form.Label className='text-primary'>City or Town</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change City or Town"
							name="cityTown"
				            value={cityTown} 
				            onChange={e => setCityTown(e.target.value)}
				            // required
				        />
			    </Form.Group>

			    <Form.Group controlId="mobileNo">
			        <Form.Label className='text-primary'>Mobile Number</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Mobile Number"
							name="mobileNo"
				            value={mobileNo} 
				            onChange={e => setMobileNo(e.target.value)}
				        />
			    </Form.Group>

			   	<Form.Group controlId="email">
			        <Form.Label className='text-primary'>Email address</Form.Label>
				        <Form.Control 
				            type="email" 
				            placeholder="Change email address"
							name="email"
				            value={email} 
				            onChange={e => setEmail(e.target.value)}
				        />
			    </Form.Group>

	            <Form.Group controlId="password">
	            <Form.Label className='text-primary'>Password</Form.Label>
	                <Form.Control 
		                type={showPassword ?  "text" : "password"} 
		                placeholder="Change Password"
						name="password"
		                value={password} 
				        onChange={e => setPassword(e.target.value)}
	                />
					<Button
						onClick={togglePasswordVisibility}>
						{showPassword ? <FaEyeSlash /> : <FaEye />}
					</Button>
					<Form.Text className="text-muted">
			            Never share your password with anyone else.
			        </Form.Text>
	            </Form.Group>
				<br></br>
				<div className="avatar-selection">
					<Form.Label className="text-primary">Change Avatar</Form.Label>
						<div className="avatar-options">
							{avatars.map((avatarUrl, index) => (
								<img
									key={index}
									src={avatarUrl}
									alt={`avatar-${index}`}
									className={`avatar ${avatar === avatarUrl ? 'selected' : ''}`}
									onClick={() => handleAvatarSelection(avatarUrl)}
									style={{ borderRadius: '50%', width: '50px', height: '50px', cursor: 'pointer', margin: '5px', border: avatar === avatarUrl ? '2px solid #007bff' : 'none' }}
								/>
							))}
						</div>
				</div>
				<Button variant="danger" type="submit" id="submitBtn">
			        Submit
			    </Button>
			</Form>
		</>
	)
}