import React from 'react';
import {Form, Button} from 'react-bootstrap';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';

export default function UpdateDiamond() {
	const navigate = useNavigate();
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [diamond_location, setDiamondLocation] = useState('');
	const [mobileNo, setMobileNo] = useState('');
	const [fbMessenger, setFbMessenger] = useState('');
	const [viberNo, setViberNo] = useState('');
	const [whatsappNo, setWhatsappNo] = useState('');

	const handleSubmit = async e => {
		e.preventDefault();
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/diamond/updateDiamond`, {
				method: 'POST',
				headers: {
					'Content-Type' : 'application/json',
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
				body: JSON.stringify({
					name: name,
					description: description,
					diamond_location: diamond_location,
					mobileNo: mobileNo,
					fbMessenger: fbMessenger,
					viberNo: viberNo,
					whatsappNo: whatsappNo
				})
			});

			const data = await response.json();
			if (response.ok) {
				console.log(data.message);
				// Show success message
				Swal.fire({
					title: 'Diamond profile updated successfully!',
					icon: 'success',
					text: data.message
				});
				navigate('/agent');
				// Optionally, redirect the user to another page after successful registration
			} else {
				console.error('Error updating user:', data.message);
				if (data.message === 'Diamond name already exists') {
					// Show alert for duplicate email
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'Diamond name already exists in the database. Please use a different name.'
					});
				} else {
					// Show error message
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'An error occurred. Please try again later.'
					});
				}
		  	}
		} catch (error) {
			console.error('Error registering user:', error.message);
			// Show error message
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Something went wrong!'
			});
		}
	};

	return (
		<>
		<h5>Update Diamond Info</h5>
		<Form onSubmit={e => handleSubmit(e)}>
				<Form.Group controlId="name">
			        <Form.Label className='text-primary'>Diamond Name</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Diamond Name"
							name="name"
				            value={name} 
				            onChange={e => setName(e.target.value)}
				        />
			    </Form.Group>

				<Form.Group controlId="description">
			        <Form.Label className='text-primary'>Description</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Description"
							name="description"
				            value={description} 
				            onChange={e => setDescription(e.target.value)}
				        />
			    </Form.Group>

			    <Form.Group controlId="diamond_location">
			        <Form.Label className='text-primary'>Diamond Location</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Location"
							name="diamond_location"
				            value={diamond_location} 
				            onChange={e => setDiamondLocation(e.target.value)}
				        />
			    </Form.Group>

			    <Form.Group controlId="mobileNo">
			        <Form.Label className='text-primary'>Mobile Number</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Mobile Number"
							name="mobileNo"
				            value={mobileNo} 
				            onChange={e => setMobileNo(e.target.value)}
				        />
			    </Form.Group>

				<Form.Group controlId="fbMessenger">
			        <Form.Label className='text-primary'>FB Messenger</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change FB Messenger"
							name="fbMessenger"
				            value={fbMessenger} 
				            onChange={e => setFbMessenger(e.target.value)}
				        />
			    </Form.Group>

				<Form.Group controlId="viberNo">
			        <Form.Label className='text-primary'>Viber Number</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Viber Number"
							name="viberNo"
				            value={viberNo} 
				            onChange={e => setViberNo(e.target.value)}
				        />
			    </Form.Group>

				<Form.Group controlId="whatsappNo">
			        <Form.Label className='text-primary'>Whatsapp Number</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Whatsapp Number"
							name="whatsappNo"
				            value={whatsappNo} 
				            onChange={e => setWhatsappNo(e.target.value)}
				        />
			    </Form.Group>

				<br/>
				<Button variant="danger" type="submit" id="submitBtn">
			        Submit
			    </Button>
			</Form>
		</>
	)
}