import React from 'react';
import {Card, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import UserContext from '../UserContext';
import {useContext} from 'react';

export default function Profile() {
	const {user} = useContext(UserContext)
	const levelplus = (user.levelplus > user.playerlevel) ? user.levelplus : user.playerlevel;

    return (
		<div className='pt-3 pb-3'>
			<h5 className="profile-name p-1 text-primary text-center">Welcome back {(user.isMerchant) ? "merchant" : ""} {user.username}</h5>
			<Col xs={12} md={9}>
				<Card className=''>
					<Card.Body>
						<Card.Title className='text-primary'>Name: {user.firstName} {user.lastName}</Card.Title>
						<Card.Text>Email: {user.email}</Card.Text>
						<Card.Text>Mobile No: {user.mobileNo}</Card.Text>
						<Card.Text>Facebook Messenger: {user.fbMessenger}</Card.Text>
						<Card.Text>Viber No: {user.viberNo}</Card.Text>
						<Card.Text>WhatsApp No: {user.whatsappNo}</Card.Text>
						<Card.Text>City / Town: {user.cityTown}</Card.Text>
						{(user.isAgent) ?
							<Card.Text>Referral Code / Link: <strong>{user.referral_code}</strong></Card.Text>
							:
							<></>
						}
						{(user.agent !== null) ?
							<Card.Text>Loader Code: <strong>{user.parent_referral_code}</strong></Card.Text>
							:
							<></>
						}
						{(!user.isAdmin) ?
							<>
								<Card.Text>Player Level: {levelplus}</Card.Text>
								<Card.Text>Experience: {user.exp}</Card.Text>
								<Card.Text>Gold Coins:<strong> {user.gold}🪙</strong></Card.Text>
								<Card.Text>Diamonds: <strong>{user.diamond}💎</strong></Card.Text>
								<Card.Text>Peso Value: <strong>₱ {(user.diamond/2)}💰</strong></Card.Text>
								<Card.Text>Win: {user.win}</Card.Text>
								<Card.Text>Loss: {user.loss}</Card.Text>
								<Card.Text>Current Prediction: {user.currentBet} diamonds</Card.Text>
							</>
							:
							<></>
						}
						{(!user.referral_code) ?
							<>
								<Link className="btn btn-danger" to={`/setreferrallink/${user.id}`}>Set Referral Code</Link>
								<br></br>
								<br></br>
							</>
							:
							<></>
						}
						{(!user.isAdmin) ?
							<>
								<Link className="btn btn-primary" to={`/profile/${user.id}`}>Set Prediction</Link>
								<br></br>
								<br></br>
								<Link className="btn btn-success" to={`/chicken`}>Go to My Farm</Link>
								<br></br>
								<br></br>
								{(!user.isAgent) ?
									<>
										<Link className="btn btn-danger" to={"/changeloader"}>Change My Loader</Link>
										<br></br>
										<br></br>
									</>
									:
									<>
									{(user.subAgentCategory !== 5) ?
										<>
											<Link className="btn btn-danger" to={"/agentchangeloader"}>Change My Loader</Link>
											<br></br>
											<br></br>
										</>
										:
										<></>
									}
									</>
								}
							</>
							:
							<></>
						}
						<Link className="btn btn-primary" to={"/editprofile"}>Edit Profile</Link>
					</Card.Body>
				</Card>
			</Col>
		</div>
    )
}