import React from 'react';
import {Card, CardImg, Col, Row} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

export default function ProductCard(product){

  const {name, description, price, imageUrl, _id} = product.productProp
  const navigate = useNavigate();

  return(
    <div className='cardSize'>
    <Row className="mt-1 mb-1">
      <h4 className='text-danger'>{name}</h4>
      <Col>
        <Card>
          <Card.Body onClick={() => navigate(`/product/${_id}`)}>
            <Card.Subtitle>Description:</Card.Subtitle>
            <Card.Text>{description}</Card.Text>
            <Card.Subtitle>Price: {price}💎</Card.Subtitle>
            <Card.Subtitle>Peso Value:₱ {price/2}</Card.Subtitle>
            <br></br>
            <Link className="btn btn-primary" to={`/product/${_id}`}>Details</Link>
            <Card.Subtitle className='pt-3'>Image</Card.Subtitle>
            <CardImg width="50%" height="50%" top src={imageUrl}/>
          </Card.Body>
        </Card>
      </Col>
      </Row>
    </div>
  )
}
