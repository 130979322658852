import React from 'react';
import {Card, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import UserContext from '../UserContext';
import {useContext} from 'react';

export default function SellAgentTransactionCard({transaction}){

  const {user} = useContext(UserContext)

  return(
    <div className='pb-5'>
      {(transaction.transferredTo === user.id) ?
      <Col xs={12} md={6}>
        <Card>
          <Card.Body>
              <Card.Title>Trading</Card.Title>
              <Card.Text><strong>Requested By:</strong> {transaction.transferredByName}</Card.Text>
              <Card.Text><strong>Diamond Amount:</strong> {transaction.diaAmount}</Card.Text>
              <Card.Text><strong>Loader Transaction:</strong> {(transaction.agentToAgent? "Yes" : "No")}</Card.Text>
              <Card.Text><strong>Approved:</strong> {(transaction.isApproved? "Yes" : "No")}</Card.Text>
              <Card.Text><strong>Date:</strong> {transaction.timeStamp}</Card.Text>
              {(!user.isAdmin) ? (
                (!transaction.isApproved) ?
                  <Link className="btn btn-danger" to={`/transaction/agenttransaction/${transaction._id}/${user.id}`}>Trade</Link>
                :
                  <></>
              ) : (
                <></>
              )}
          </Card.Body>
        </Card>
      </Col>
      :
      <></>
      }
    </div>
  )
}
