import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../styles/productStyles.css';

export default function ProductList() {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/shopproduct/`);
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const PLACEHOLDER_IMAGE_URL = "https://fowloffury.s3.ap-southeast-1.amazonaws.com/shop/placeholder.png"

  return (
    <div className="container-mall">
      <header className="header">
        <h4 className="brand">Shopping Mall</h4>
      </header>

      <section className="banner-mall">
      <h4>Get Ready for the Ultimate Black Friday Shopping Madness</h4>
        <button className="shop-now-btn">Shop Now</button>
      </section>

      <section className="products">
        {products.length > 0 ? (
          products.map((product, index) => (
            <div key={index} className="product-card">
              <div
                to={`/shoppingmall/${product._id}`}
                className="productCard"
                onClick={() => navigate(`/shoppingmall/${product._id}`, { state: { product } })}
              >
                <img 
                  src={(product.imageUrl[0]) ? product.imageUrl[0] : PLACEHOLDER_IMAGE_URL}
                  alt={product.name} 
                />
                <h1>{product.name}</h1>
                <p>Price: {product.sellingprice} 💎</p>
                <p>Php Value: ₱{product.sellingprice/2}</p>
                <p>Product Code: {product.productCode}</p>
                <p>⭐ {product.rating} ({product.reviews})</p>
              </div>
            </div>
          ))
        ) : (
          <p className="noProductsText">No products available.</p>
        )}
      </section>
    </div>
  );
};