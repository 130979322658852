import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import '../styles/productDetailStyles.css';
import ReactImageMagnify from 'react-image-magnify';

const MallProductDetail = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [mainImage, setMainImage] = useState("");

  useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/shopproduct/${productId}`);
        const data = await response.json();
        setProduct(data);
        setMainImage(data.imageUrl && data.imageUrl.length > 0 ? data.imageUrl[0] : PLACEHOLDER_IMAGE_URL);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetail();
  }, [productId]);

  if (!product) {
    return <p>Loading...</p>;
  }

  const PLACEHOLDER_IMAGE_URL = "https://fowloffury.s3.ap-southeast-1.amazonaws.com/shop/placeholder.png"

  return (
    <div className="product-detail-container">
      <div className="product-image-gallery">
      <div className="thumbnail-gallery">
          {product.imageUrl?.slice(0, 5).map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`Thumbnail ${index}`}
              className="thumbnail"
              onClick={() => setMainImage(img)}
            />
          ))}
        </div>
        <div className="main-product-image-container">
          <ReactImageMagnify
              {...{
                smallImage: {
                  alt: product.name,
                  src: mainImage || PLACEHOLDER_IMAGE_URL,
                  width: 400,
                  height: 450,
                },
                largeImage: {
                  src: mainImage || PLACEHOLDER_IMAGE_URL,
                  width: 1500,
                  height: 1800,
                },
                enlargedImageContainerDimensions: {
                  width: '150%',
                  height: '150%',
                },
                enlargedImageContainerStyle: {
                  border: '2px solid #000',
                  boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
                  borderRadius: '4px',
                  // position: 'absolute',
                  // bottom: '0',
                  // right: '50%',
                  // transform: 'translateX(-50%) translateY(0)',
                  // zIndex: 10,
                  overflow: 'hidden',
                },
                enlargedImagePosition: 'over',
                isHintEnabled: true,
              }}
            />
          {/* <Zoom>
            <img
              src={(mainImage) ? mainImage : PLACEHOLDER_IMAGE_URL}
              alt={product.name}
              className="main-product-image" />
          </Zoom> */}
        </div>
      </div>
      <div className="product-info">
        <h1>{product.name}</h1>
        <p className="product-rating">{product.description}</p>
        <p className="product-rating">⭐ {product.rating} ({product.reviews} Reviews)</p>
        <p className="product-code">Product code: {product.productCode}</p>
        <p className="product-price">Price: {product.sellingprice}💎</p>
        <p className="product-price">Php Value: ₱{product.sellingprice/2}</p>
        <div className="quantity-controls">
          <button>-</button>
          <span>1</span>
          <button>+</button>
        </div>
        <button className="add-to-bag-button">ADD TO CART</button>
        <div className="delivery-options">
          <p>Check stock in stores (Actual stocks may vary)</p>
          <p>DELIVERY METHOD</p>
          <div className="delivery-methods">
            <span>Home Delivery</span>
            <span>Click & Collect</span>
          </div>
          <div className="continue">
            <button className="continue-button" onClick={() => navigate(`/shoppingmall`)}>Continue Shopping</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MallProductDetail;
