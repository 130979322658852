import React, { useState, useEffect } from 'react';
import Loading from '../components/Loading';
import { Button } from 'react-bootstrap';
import DiamondCard from '../components/DiamondCard';
import '../App.css';

export default function Diamonds(){
    const [diamonds, setDiamonds] = useState([]);
	// const [allDiamonds, setAllDiamonds] = useState([]);
    const [groupedDiamonds, setGroupedDiamonds] = useState({});
    // const [groupedAllDiamonds, setGroupedAllDiamonds] = useState({});
    const [isLoading, setIsLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredDiamonds, setFilteredDiamonds] = useState([]);
    const [searchMessage, setSearchMessage] = useState('');

	useEffect(() => {
        fetchDiamonds();
        // fetchAllDiamonds();
    }, []);

	const fetchDiamonds = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/diamond/getAllDiamonds`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setDiamonds(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching diamonds:', error);
        }
    };

    const groupByLocation = (diamonds) => {
        return diamonds.reduce((acc, diamond) => {
            const location = diamond.diamond_location || 'Unknown Location';
            if (!acc[location]) acc[location] = [];
            acc[location].push(diamond);
            return acc;
        }, {});
    };
	
	const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

	const handleSearch = () => {
        filterResults();
    };

	const filterResults = () => {
        const searchTermLower = searchTerm.toLowerCase();
        const filteredDiamonds = diamonds.filter((diamond) =>
            Object.values(diamond).some((value) =>
                typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
            )
        );

        if (filteredDiamonds.length === 0) {
            setSearchMessage('No results found.');
        } else {
            setSearchMessage('');
        };

        setGroupedDiamonds(groupByLocation(filteredDiamonds));

        // let diamondResults = diamonds; // Initially set to all profiles
        // if (searchTerm.trim() !== '') {
        //     diamondResults = diamonds.filter((diamond) =>
        //         Object.values(diamond).some((value) =>
        //             typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
        //         )
        //     );

        //     if (diamondResults.length === 0) {
        //         setSearchMessage('Keyword not found');
        //     } else {
        //         setSearchMessage('');
        //     }
        // } else {
        //     setSearchMessage('');
        // }
        // setFilteredDiamonds(diamondResults);
    };

    const renderDiamondGroups = (groupedData) => {
        return Object.entries(groupedData).map(([location, diamonds]) => (
            <div key={location}>
                {/* <h6 className='text-danger'>{location}</h6> */}
                <p className='text-danger' style={{fontSize: 13}}><strong>{location}</strong></p>
                <div className="cardChicken mb-2">
                    {diamonds.map((diamond) => (
                        <DiamondCard key={diamond._id} diamond={diamond} />
                    ))}
                </div>
            </div>
        ));
    };

	return(			
		isLoading ? <Loading/>
		:
		<>
				<h5 className="profile-list pt-3 pb-3 text-center">Buy/Trade Diamonds</h5>
				<div className="search-bar">
				<span>
					<input
						type="text"
						placeholder="Search by username, first name, last name, email, mobile no., etc..."
						value={searchTerm}
						onChange={handleSearchInputChange}
					/>
					&nbsp;
					<Button onClick={handleSearch}>Search</Button>
					</span>
					<p>{searchMessage}</p>
				</div>
                {renderDiamondGroups(groupedDiamonds)}
				{/* <div className='cardChicken mb-3'>
					{filteredDiamonds.map((diamond) => (
						<DiamondCard key={diamond._id} diamond={diamond} />
					))}
				</div> */}
		</>
	)
}
