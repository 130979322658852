import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useState, useEffect, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import UserContext from '../UserContext';
import Swal from 'sweetalert2';
import "../styles/Login.css";

export default function Login() {
    const {user, setUser} = useContext(UserContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const retrieveUser = (token) => {
        fetch(`${process.env.REACT_APP_API_URL}/users/details`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .then(result => {

            setUser({
                id: result._id,
                isAdmin: result.isAdmin
            })
        })
    }

    const refresh = () => {
        window.location.reload();
    }

    function authenticate(event){
        event.preventDefault()

        fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
        .then(response => response.json())
        .then(result => {
            if (result.message === "User is inactive") {
                Swal.fire({
                    title: 'Authentication Failed!',
                    icon: 'error',
                    text: 'User is inactive.'
                });
            } else if (typeof result.access !== "undefined") {

                localStorage.setItem('token', result.access);

                retrieveUser(result.access)

                Swal.fire({
                    title: 'Login Successful!',
                    icon: 'success',
                    text: 'Welcome to Fowl of Fury!'
                })

            } else {
                Swal.fire({
                    title: 'Authentication Failed!',
                    icon: 'error',
                    text: 'Invalid Email or password'
                });
            }

            if (user.isAdmin) {
                navigate('/admin');
            } else {
                navigate('/');
            }
            refresh();
        });
    }

    useEffect(() => {
        if((isAdmin === true)){
            setIsAdmin(true)
        } else {
            setIsAdmin(false)
        }
    }, [isAdmin])

    useEffect(() => {
        if((email !== '' && password !== '' && validateEmail(email))){
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [email, password])

    return (
        (user.id !== null || user.isActive) ?
            <>
                {(user.isAdmin) ? (
                    <Navigate to= {"/admin"} />
                ) : (
                    <Navigate to= {"/"} />
                )}
            </>
        :
        <div className="login-container">
            <div className='login_box'>
                <div className='login-header'>
                    <span>Login</span>
                    <img
                        width="100%"
                        height="150px"
                        src="/images/Chicken_01.png"
                        alt="imgChicken"
                    />
                </div>
                <div className='input_box'>
                    <input type="email" className='input-field' id='email' value={email} onChange={(e) => setEmail(e.target.value)} required></input>
                    <label for='email' className='label'>Email</label>
                    <i className="icon">
                    <ion-icon  name="person"></ion-icon>
                    </i>
                </div>
                <div className='input_box'>
                    <input type={showPassword ?  "text" : "password"} className='input-field' id='password' value={password} onChange={(e) => setPassword(e.target.value)} required></input>
                    <label for='password' className='label'>Password</label>
                    <i className="icon">
                    <ion-icon className="icon" name="key"></ion-icon>
                    </i>
                </div>
                <div>
                    <span>
                    <Button onClick={togglePasswordVisibility}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                    &nbsp;&nbsp;
                    <p className="text-muted">
                        Never share your password with anyone else.
                    </p>
                    </span>
                </div>
                <div className='forgot'>
                    <Link to={"/forgot-password"} style={{ textDecoration: 'none', color: 'blue'}}>Forgot password?</Link>
                </div>
                <div>
                    <Button 
                        className='login-button mt-3' 
                        onClick={event => authenticate(event)}
                        id="submitBtn"
                        type="submit"
                        variant={(isActive) ? "primary" : "danger"}
                        color={(isActive) ? "#1f2937" : "#ee0050"}
                        disabled={!isActive}
                    > Login
                    </Button>
                </div>
                <p className="register-link">
                    Don't have an account? <Link to={"/register"} style={{ textDecoration: 'none', color: 'blue'}}>Register here</Link>
                </p>
            </div>
        </div>
    );
}
