import React, { useEffect, useContext, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useNavigate, NavLink } from 'react-router-dom';
import UserContext from '../UserContext';
// import { ThemeContext } from '../ThemeProvider';
import Swal from 'sweetalert2';
import { FaMoon, FaSun } from 'react-icons/fa';
import '../styles/Login.css';

export default function AppNavbar(){
	const {user} = useContext(UserContext);
	// const { theme, toggleTheme } = useContext(ThemeContext);
	const navigate = useNavigate();
	const [requests, setRequests] = useState([]);
	const [pendingRequests, setPendingRequests] = useState(0);

	useEffect(() => {
        fetchPendingRequests();
        const interval = setInterval(fetchPendingRequests, 30000); // Refresh every 30 seconds
        return () => clearInterval(interval);
    }, []);

	const fetchPendingRequests = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/loaderRequest/getPendingRequests`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setRequests(data);
			setPendingRequests(data.length);
			if (data.length > 0) {
                showPendingRequests(data[0])
            };

        } catch (error) {
            console.error('Error fetching pending requests:', error);
        }
    };

	const handleBellClick = () => {
        if (pendingRequests > 0) {
            Swal.fire({
                title: 'Pending Requests',
                text: `You have ${pendingRequests} pending loader change request(s).`,
                icon: 'info',
                confirmButtonText: 'View Requests',
            }).then((result) => {
                if (result.isConfirmed) {
                    showPendingRequests();
                }
            });
        } else {
            Swal.fire('No pending requests', '', 'info');
        }
    };

	const showPendingRequests = () => {
        if (requests.length > 0) {
            const request = requests[0];
            Swal.fire({
                title: 'Player Loader Change Request',
                text: `Player ${request.userName} wants to join you.`,
                icon: 'info',
                allowOutsideClick: false,
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: 'Accept',
                denyButtonText: 'Deny',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await acceptLoaderRequest({ requestId: request._id });
                    Swal.fire('Accepted', 'Request accepted successfully.', 'success');
					navigate('/');
                }
                if (result.isDenied) {
                    await denyLoaderRequest({ requestId: request._id });
                    Swal.fire('Denied', 'Request denied.', 'error');
					navigate('/');
                }
            });
		};
    };

    const acceptLoaderRequest = async (data) => {
        await fetch(`${process.env.REACT_APP_API_URL}/loaderRequest/acceptRequest`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data),
        });
    };

    const denyLoaderRequest = async (data) => {
        await fetch(`${process.env.REACT_APP_API_URL}/loaderRequest/denyRequest`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data),
        });
    };

	return(
		<>
		<Navbar className="p-1" bg="light" expand="lg" sticky="top">
			<img  src="/fowloffury_profile.jpg" alt="Logo" className='circle-logo'/>
			<Navbar.Brand className="ml-3" style={{fontSize: 16}} as={NavLink} to= {(user.isAdmin) ? "/admin" : "/"} >FOWL of FURY</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav"/>
			<Navbar.Collapse id="basic-navbar-nav" >
				<Nav className="mr-auto">
					{(user.id) ?
						<>
							{(user.isAgent === true) ? (
								<>
									{(user.isSuperAdmin === true) ?
										<>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/"}>Home</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/profile"}>Profile</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/superadmin"}>Admin Dashboard</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/diamond"}>Diamonds</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/predict"}>Prediction</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/history"}>Matches</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/product"}>Market Place</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/shoppingmall"}>Shopping Mall</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/merchant"}>Merchants</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/adsdashboard"}>Advertisement</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/logout"}>Logout</Nav.Link>
										</>
										:
										<>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/"}>Home</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/profile"}>Profile</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/agent"}>Dashboard</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/diamond"}>Diamonds</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/predict1"}>Prediction</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/history"}>Matches</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/product"}>Market Place</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/shoppingmall"}>Shopping Mall</Nav.Link>
											{(user.isMerchant) ? (
												<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/merchantproduct"}>My Products</Nav.Link>
											) : (
												<></>
											)}
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/helpdesk"}>Helpdesk</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/logout"}>Logout</Nav.Link>
										</>
									}
								</> 
								) : (
									<>
									{(user.isAdmin === true) ? (
										<>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/admin"}>Admin Dashboard</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/adsdashboard"}>Advertisement</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/merchant"}>Merchants</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/profile"}>Profile</Nav.Link>
											<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/logout"}>Logout</Nav.Link>
										</>
									) : (
									<>
										<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/"}>Home</Nav.Link>
										<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/profile"}>Profile</Nav.Link>
										<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/diamond"}>Diamonds</Nav.Link>
										<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/predict1"}>Prediction</Nav.Link>
										<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/history"}>Matches</Nav.Link>
										<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/product"}>Market Place</Nav.Link>
										<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/shoppingmall"}>Shopping Mall</Nav.Link>
										{(user.isMerchant) ? (
												<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/merchantproduct"}>My Products</Nav.Link>
											) : (
												<></>
											)}
										<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/helpdesk"}>Helpdesk</Nav.Link>
										<Nav.Link style={{fontSize: 15}} as={NavLink} to={"/logout"}>Logout</Nav.Link>
									</>
									)}
									</>
							)}
						</>
						:
						<>
							<Nav.Link className="d-flex justify-content-end" style={{fontSize: 15}} as={NavLink} to={"/login"}>Login</Nav.Link>
							<Nav.Link className="d-flex justify-content-end" style={{fontSize: 15}} as={NavLink} to={"/register"}>Register</Nav.Link>
						</>
					}	
				</Nav>
				{user.id && (
					<Nav className="ml-auto">
						{(user.isAgent) ? (
							<>
								&nbsp;&nbsp;&nbsp;
								<Nav.Link onClick={handleBellClick} style={{ position: 'relative', fontSize: 18 }}>
									<span className={pendingRequests > 0 ? 'blink' : ''}>🔔 </span>
										{pendingRequests > 0 && (
											<span style={{
												position: 'absolute',
												top: '1px',
												right: '-2px',
												padding: '2px 6px',
												borderRadius: '50%',
												backgroundColor: 'red',
												color: 'white',
												fontSize: '8px',
												fontWeight: 'bold',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}>
												{pendingRequests}
											</span>)}
								</Nav.Link>
								&nbsp;&nbsp;&nbsp;
								<Navbar.Text className="text-right" style={{ fontSize: 15, color: '#333' }}>
									&nbsp;&nbsp;&nbsp;<span style={{ fontWeight: 'bold' }}>Earnings:</span>&nbsp;<span style={{ color: 'blue' }}>{user.earnagentdiamond}&nbsp;💎</span>
									&nbsp;&nbsp;&nbsp;<span style={{ fontWeight: 'bold' }}>Diamonds: {user.diamond}</span> 
									{/* <ion-icon name="wallet" style={{ color: 'red' }}></ion-icon> */}
								</Navbar.Text>
								<Nav.Link style={{ position: 'relative', fontSize: 16, backgroundColor: 'whitesmoke' }} as={NavLink} to={(user.diamond) <= 200 ? '/request-agentdiamond' : ''}>
									<span className={(user.diamond) <= 200 ? 'blink' : ''}>💎</span>
									<span className={(user.diamond) <= 200 ? 'blink' : ''} style={{ fontSize: 13, color: 'red', backgroundColor: 'whitesmoke' }}>{(user.diamond) <= 200 ? 'Low Balance' : ''}</span>
								</Nav.Link>
								&nbsp;&nbsp;&nbsp;
								<Nav.Link style={{ position: 'relative', fontSize: 18 }}>
									🛒
										{pendingRequests > 0 && (
											<span style={{
												position: 'absolute',
												top: '1px',
												right: '-2px',
												padding: '2px 6px',
												borderRadius: '50%',
												backgroundColor: 'red',
												color: 'white',
												fontSize: '8px',
												fontWeight: 'bold',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}>
												{pendingRequests}
											</span>)}
								</Nav.Link>
							</>
						) : (
							<>
								{(user.isAdmin) ? (
									<>
									</>
								):(
									<>
										&nbsp;&nbsp;&nbsp;
										<Nav.Link style={{ position: 'relative', fontSize: 18 }}>
											<span className={pendingRequests > 0 ? 'blink' : ''}>🔔 </span>
												{pendingRequests > 0 && (
													<span style={{
														position: 'absolute',
														top: '1px',
														right: '-2px',
														padding: '2px 6px',
														borderRadius: '50%',
														backgroundColor: 'red',
														color: 'white',
														fontSize: '8px',
														fontWeight: 'bold',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}>
														{pendingRequests}
													</span>)}
										</Nav.Link>
										&nbsp;&nbsp;&nbsp;
										<Navbar.Text className="text-right" style={{ fontSize: 15, color: '#333' }}>
											&nbsp;&nbsp;&nbsp;<span style={{ fontWeight: 'bold' }}>Current Diamond: {user.diamond}</span> 
										</Navbar.Text>
										<Nav.Link style={{ position: 'relative', fontSize: 16, backgroundColor: 'whitesmoke' }} as={NavLink} to={(user.diamond) <= 200 ? `/diamond` : ''}>
											<span className={(user.diamond) <= 200 ? 'blink' : ''}>💎</span>
											<span className={(user.diamond) <= 200 ? 'blink' : ''} style={{ fontSize: 13, color: 'red', backgroundColor: 'whitesmoke' }}>{(user.diamond) <= 200 ? 'Low Balance' : ''}</span>
										</Nav.Link>
										&nbsp;&nbsp;&nbsp;
										<Nav.Link style={{ position: 'relative', fontSize: 18 }}>
											🛒
												{pendingRequests > 0 && (
													<span style={{
														position: 'absolute',
														top: '1px',
														right: '-2px',
														padding: '2px 6px',
														borderRadius: '50%',
														backgroundColor: 'red',
														color: 'white',
														fontSize: '8px',
														fontWeight: 'bold',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}>
														{pendingRequests}
													</span>)}
										</Nav.Link>
									</>
								)}
							</>
						)}
					</Nav>
				)}
			</Navbar.Collapse>
			{user.id && (
				<Nav>
					<Nav.Link style={{ fontSize: 16, backgroundColor: 'whitesmoke' }} as={NavLink} to="/profile">
						<img
							src={user.avatar}
							alt="avatar"
							style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '6px' }}
						/>
					</Nav.Link>
				</Nav>
			)}
			<button className="theme-toggle">
				<FaMoon />
			</button>
		</Navbar>
		</>
	)
}