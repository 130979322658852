import React from 'react';
import {Button, Card, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import "../App.css";

export default function HomeDiamondCard({diamond}){

  return(
    <div className='cardSize'>
    <Row>
      <Col>
        <Card>
          <div className='p-2'>
            <p style={{fontSize: 11}}><strong>{diamond.name}</strong></p>
              <Link className="btn btn-primary" style={{fontSize: 10}} to={`/register/${diamond.agentId}`}>Register</Link>
          </div>
        </Card>
      </Col>
      </Row>
    </div>
  )
}
