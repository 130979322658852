import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const CreateAds = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [urlAds, setUrlAds] = useState('');
  const [dayDuration, setDayDuration] = useState('');
  const [hrsDuration, setHrsDuration] = useState('');
  const [minDuration, setMinDuration] = useState('');
  const navigate = useNavigate();

  const createAdvertisement = async (adData) => {
    const formData = new FormData();
    Object.keys(adData).forEach(key => formData.append(key, adData[key]));
  
    const response = await fetch(`${process.env.REACT_APP_API_URL}/advertisement/createads`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    });
  
    if (response.status === 403) {
      Swal.fire({
        title: 'Denied',
        text: "User is not Super Admin",
        icon: 'error',
        allowOutsideClick: false,
        confirmButtonText: 'Ok',
      });
      return null;
    }

    return await response.json();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

      const now = new Date();
      const expiration = new Date(
        now.getTime() + dayDuration * 86400000 + hrsDuration * 3600000 + minDuration * 60000);

      const adData = {
        name,
        description,
        amount,
        image,
        video,
        urlAds,
        expiration,
      };

    try {
      const result = await createAdvertisement(adData);
      if (result) {
        Swal.fire({
          title: 'Success',
          text: "Ads created successfully!",
          icon: 'success',
          allowOutsideClick: false,
          confirmButtonText: 'Ok',
        });
        navigate('/adsdashboard');
      }
    } catch (error) {
      console.error('Error creating ads:', error);
      Swal.fire({
        title: 'Error',
        text: "Failed to create ads.",
        icon: 'error',
        allowOutsideClick: false,
        confirmButtonText: 'Ok',
      });
      // Swal("Error", "Failed to create ads.", "error");
    }
  };

  return (
    <div>
      <h4>Create a New Ads</h4>
      <form onSubmit={handleSubmit}>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
        <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Amount" />
        <input type="file" onChange={(e) => setImage(e.target.files[0])} required />
        <input type="file" onChange={(e) => setVideo(e.target.files[0])} required />
        <input type="text" value={urlAds} onChange={(e) => setUrlAds(e.target.value)} placeholder="Ad URL" />
        <input type="number" value={dayDuration} onChange={(e) => setDayDuration(e.target.value)} placeholder="Days" />
        <input type="number" value={hrsDuration} onChange={(e) => setHrsDuration(e.target.value)} placeholder="Hours" />
        <input type="number" value={minDuration} onChange={(e) => setMinDuration(e.target.value)} placeholder="Minutes" />
        <button type="submit">Create Ads</button>
      </form>
    </div>
  );
};

export default CreateAds;