import React, { useState, useEffect, useContext } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading';
import AdsCard from '../components/AdsCard';
import UserContext from '../UserContext';

export default function Liquidation() {
    const { user } = useContext(UserContext);
    const [ads, setAds] = useState([]);
    const [filteredAds, setFilteredAds] = useState([]);
    const [totalActiveAmount, setTotalActiveAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchMessage, setSearchMessage] = useState('');

    const [ongoingAds, setOngoingAds] = useState([]);
    const [inactiveAds, setInactiveAds] = useState([]);

    useEffect(() => {
        fetchAds();
    }, []);

	// Automatic search on Searchbar
	useEffect(() => {
        filterResults();
    }, [searchTerm]);

    const fetchAds = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/advertisement/adslist`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setAds(data);
            calculateTotalActiveAmount(data); // Calculate total amount
            filterOngoingAndInactiveAds(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching ads:', error);
        }
    };

    // Calculate the total amount of all ads
    const calculateTotalActiveAmount = (ads) => {
        const total = ads.filter((ad) => ad.isActive).reduce((sum, ad) => sum + (ad.amount || 0), 0);
        setTotalActiveAmount(total);
    };

    // Filter ongoing and inactive ads
    const filterOngoingAndInactiveAds = (ads) => {
        const ongoing = ads.filter((ad) => ad.isOngoing && ad.isActive);
        const inactive = ads.filter((ad) => !ad.isActive);

        setOngoingAds(ongoing);
        setInactiveAds(inactive);
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

	const handleSearch = () => {
        filterResults();
    };

	const filterResults = () => {
        const searchTermLower = searchTerm.toLowerCase();
        let adsResults = ads;
        if (searchTerm.trim() !== '') {
            adsResults = ads.filter((ad) =>
                Object.values(ad).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );
            if (adsResults.length === 0) {
                setSearchMessage('Keyword not found');
            } else {
                setSearchMessage('');
            }
        } else {
            setSearchMessage('');
        }
        setFilteredAds(adsResults);
    };

    return (
        isLoading ? <Loading /> :
            <>
                <h5 className="profile-name p-1 text-primary">Advertisements</h5>
                <br />
                <a href="#list" className='text-black mb-3'>Go to List</a>
                <Card className='mt-3'>
                    <Card.Body>
                        <Card.Text>All Advertisement: {ads.length}</Card.Text>
                        <Card.Text>All Active Advertisement: {ongoingAds.length}</Card.Text>
                        <Card.Text>Total Earnings from Ads: Php {totalActiveAmount.toLocaleString()}</Card.Text>
                        {(user.isSuperAdmin) ? (
                            <Link className="btn btn-danger" to={`/createads`}>Create ADS</Link>
                        ) : (
                            <></>
                        )}
                    </Card.Body>
                </Card>
                <br />
				<br />
                <h5 id="list" className="profile-list p-1 text-primary">Advertisement List</h5>
                <h5 className="profile-list p-1 text-danger">Ongoing Ads</h5>
                <div>
                    {ongoingAds.length > 0 ? (
                        ongoingAds.map((ad) => <AdsCard key={ad._id} ad={ad} />)
                    ) : (
                        <p>No ongoing ads found.</p>
                    )}
                </div>
                <br/>
                <h5 className="profile-list p-1 text-danger">Inactive Ads</h5>
                <div>
                    {inactiveAds.length > 0 ? (
                        inactiveAds.map((ad) => <AdsCard key={ad._id} ad={ad} />)
                    ) : (
                        <p>Inactive ads not found.</p>
                    )}
                </div>
            </>
    );
}
