import React from 'react';
import {useState, useEffect, useContext} from 'react';
import {Button, Card, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import UserContext from '../UserContext';
import Swal from 'sweetalert2';
import "../App.css";

export default function ChangeLoaderCard({diamond}){
  const {user} = useContext(UserContext);
  const userId = user.id;
  const agentId = diamond.agentId;

  const createLoaderRequest = async (data) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/loaderRequest/createRequest`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(data),
    });
  
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to create request');
    }
  
    return response.json();
  };
  

  const handleChangeLoader = async () => {
    try {
      const response = await createLoaderRequest({ userId, agentId });
      Swal.fire('Success', response.message, 'success');
    } catch (error) {
      Swal.fire('Error', error.response?.data.message || 'Failed to send request', 'error');
    }
  };

  return(
    <div className='cardSize'>
    <Row>
      <Col>
        <Card>
          <div className='p-2'>
            <p style={{fontSize: 11}}><strong>{diamond.name}</strong></p>
              <Button className="btn btn-primary" onClick={handleChangeLoader} style={{fontSize: 10}}>Request</Button>
          </div>
        </Card>
      </Col>
      </Row>
    </div>
  )
}
