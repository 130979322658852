import React from 'react';

const OrderConfirmation = () => (
  <div className="order-confirmation">
    <h2>Thank You!</h2>
    <p>Your order has been successfully placed.</p>
  </div>
);

export default OrderConfirmation;
