import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../UserContext'
import Swal from 'sweetalert2';

const CreateMallProduct = () => {
  const {user} = useContext(UserContext);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [sellingprice, setSellingPrice] = useState('');
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [productCode, setProductCode] = useState('');
  const navigate = useNavigate();

  const createProduct = async (productData) => {
    const formData = new FormData();
    Object.keys(productData).forEach(key => {
      if (key === 'images') {
        productData.images.forEach(image => formData.append('image', image));
      } else {
        formData.append(key, productData[key]);
      }
    });
  
    const response = await fetch(`${process.env.REACT_APP_API_URL}/shopproduct/addProduct`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    });
  
    if (response.status === 403) {
      Swal.fire({
        title: 'Denied',
        text: "User is not a Merchant",
        icon: 'error',
        allowOutsideClick: false,
        confirmButtonText: 'Ok',
      });
      return null;
    }

    return await response.json();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

      const productData = {
        name,
        userId: user.id,
        merchant: user.username,
        productCode,
        description,
        price,
        sellingprice,
        images,
        video,
      };

    try {
      const result = await createProduct(productData);
      if (result) {
        Swal.fire({
          title: 'Success',
          text: "Product created successfully!",
          icon: 'success',
          allowOutsideClick: false,
          confirmButtonText: 'Ok',
        });
        navigate('/merchant');
      }
    } catch (error) {
      console.error('Error creating product:', error);
      Swal.fire({
        title: 'Error',
        text: "Failed to create product.",
        icon: 'error',
        allowOutsideClick: false,
        confirmButtonText: 'Ok',
      });
    }
  };

  return (
    <div>
      <h4>Create a New Product</h4>
      <form onSubmit={handleSubmit}>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
        <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
        <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Price" />
        <input type="number" value={sellingprice} onChange={(e) => setSellingPrice(e.target.value)} placeholder="Selling Price" />
        {/* <input type="file" onChange={(e) => setImage(e.target.files[0])} required /> */}
        <input type="file" multiple onChange={(e) => setImages([...e.target.files])} required />
        <input type="file" onChange={(e) => setVideo(e.target.files[0])} required />
        <input type="text" value={productCode} onChange={(e) => setProductCode(e.target.value)} placeholder="Product Code" />
        <button type="submit">Create Product</button>
      </form>
    </div>
  );
};

export default CreateMallProduct;