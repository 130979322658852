import React from 'react';
import {Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import UserContext from '../UserContext';
import {useContext} from 'react';

export default function ProfileCard({profile}){

  const {user} = useContext(UserContext)

  return(
    <>
    <h4 className='text-primary'>{profile.username}</h4>
    <Card className='mb-3'>
      <Card.Body>
        <Card.Title className='text-primary'>Name: {profile.firstName} {profile.lastName}</Card.Title>
        <Card.Text>Email: {profile.email}</Card.Text>
        <Card.Text>Mobile No: {profile.mobileNo}</Card.Text>
        <Card.Text>Player Level: {profile.playerlevel}</Card.Text>
        <Card.Text>Experience: {profile.exp}</Card.Text>
        <Card.Text>Gold Coins: {profile.gold}🪙</Card.Text>
        <Card.Text>Diamonds: {profile.diamond}💎</Card.Text>
        <Card.Text>Win: {profile.win}</Card.Text>
        <Card.Text>Loss: {profile.loss}</Card.Text>
        <Card.Text>Current Prediction: {profile.currentBet} diamonds</Card.Text>
        {(!user.isAdmin) ? (
          <>
            <Link className="btn btn-success" to={`/player/${user.id}/${profile._id}`}>Transfer Player Diamonds</Link>
            <br></br>
            <br></br>
            <Link className="btn btn-primary" to={`/playertoagent/${user.id}/${profile._id}`}>Promote Player to Loader</Link>
          </>
        ) : (
          <></>
        )}
      </Card.Body>
    </Card>
    </>
  )
}
