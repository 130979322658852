// src/components/Checkout.js
import React, { useState } from 'react';
import '../styles/productStyles.css';

const Checkout = ({ cart, onOrderPlace }) => {
  const [form, setForm] = useState({ name: '', email: '', address: '' });
  const totalAmount = cart.reduce((total, item) => total + Number(item.price), 0);

  const handleInputChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  const handlePlaceOrder = () => {
    if (!form.name || !form.email || !form.address) {
      alert("Please fill all the details.");
      return;
    }
    alert("Order placed successfully!");
    onOrderPlace();
  };

  return (
    <div className="checkout">
      <h2>Checkout</h2>
      <div className="cart-items">
        {cart.map((item, index) => (
          <p key={index}>{item.name} - ₱{item.price}</p>
        ))}
      </div>
      <p>Total Amount: ₱{totalAmount.toFixed(2)}</p>
      <input name="name" value={form.name} onChange={handleInputChange} placeholder="Name" />
      <input name="email" value={form.email} onChange={handleInputChange} placeholder="Email" />
      <input name="address" value={form.address} onChange={handleInputChange} placeholder="Address" />
      <button onClick={handlePlaceOrder}>Place Order</button>
    </div>
  );
};

export default Checkout;
