import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import "../styles/Login.css";

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      setMessage(data.message);
      Swal.fire({
        title: 'Password Reset',
        text: `Password reset link sent. Check your email.`,
        icon: 'info',
        allowOutsideClick: false,
        confirmButtonText: 'Ok',
    });
    } catch (error) {
      setMessage('An error occurred while sending the reset link');
    }
  };

    return (
        <div className="login-container">
            <div className='login_box'>
                <div className='login-header'>
                    <span>Forgot Password</span>
                </div>
                <div className='input_box'>
                    <input type="text" className='input-field' id='email' value={email} onChange={(e) => setEmail(e.target.value)} required  />
                    <label for='email' className='label'>Enter Email</label>
                    <i className="icon">
                    <ion-icon  name="mail"></ion-icon>
                    </i>
                </div>
                <div>
                    <Button 
                        className='login-button mt-3' 
                        onClick={handleForgotPassword}
                        id="submitBtn"
                        type="submit"
                        variant="danger"
                    >Send Reset Link
                    </Button>
                </div>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
}
