import React from 'react';
import {useState, useEffect, useContext} from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';
import Swal from 'sweetalert2';

export default function PlayerToAgentView() {
    const {user} = useContext(UserContext)
    const navigate = useNavigate()
    const {profileId, agentId} = useParams()
    const [userId, setUserId] = useState('')
    const [agentpercentage, setAgentPercentage] = useState('')
    const [isActive, setIsActive] = useState(false)

    const setAgent = (profileId, agentId) => {
		fetch(`${process.env.REACT_APP_API_URL}/users/userToAgent/${agentId}/${profileId}`, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
                agentpercentage: agentpercentage
			})
		})
		.then(response => response.json())
		.then(result => {
			if(result) {
				Swal.fire({
					title: "Success!",
					icon: "success",
					text: "User promoted to Loader!"
				})
                navigate('/agent');
			} else {
				console.log(result)
				Swal.fire({
					title: "Something went wrong!",
					icon: "error",
					text: "Please try again."
				})
			}
		})
	}

    function setToAgent(event){
        event.preventDefault()
        setAgent(profileId, agentId);
    }

    useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/profile/${user.userId}`)
		.then(response => response.json())
		.then(result => {
			console.log(result.username)
			console.log(result.email)
			setUserId(result.userId)
		})
	}, [userId])

    useEffect(() => {
        if( ( parseInt(agentpercentage) !== 0 && parseInt(agentpercentage) % 10 === 0 && parseInt(agentpercentage) < 100 ) ){
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [agentpercentage])

    return (
        <Row className="mt-4 mb-4">
            <Col>
                <h2 className='text-black'>Promote Player to Loader?</h2>
                <p className='text-primary'>Set Loader Percentage here :</p>
                    <Form onSubmit={event => setToAgent(event)}>
                        <Form.Group controlId="setToAgent">
                            <Form.Control 
                                type="number" 
                                placeholder="Enter loader's percentage"
                                value={agentpercentage}
                                onChange={event => setAgentPercentage(event.target.value)}
                                required
                            />
                        </Form.Group>
                        <br></br>
                        {   isActive ?
                            <Button variant="danger" type="submit" id="submitBtn">
                                Promote to Loader
                            </Button>
                            :
                            <Button variant="danger" type="submit" id="submitBtn" disabled>
                                Promote to Loader
                            </Button>
                        }
                    </Form>
                <p className='text-primary'>Go back to the <Link to= {"/"} >homepage.</Link></p>
            </Col>
        </Row>
    )
}