import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/MainDashboard.css';
import Swal from 'sweetalert2';

const Advertisement = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/advertisement/featured`)
      .then((response) => response.json())
      .then((data) => setImages(data))
      .catch((error) => console.error('Error fetching ads:', error));
  }, []);

  const handleImageClick = (image) => {
    window.open(image.urlAds, '_blank');
    
    if (image.video) {
      const videoElement = document.createElement('video');
      videoElement.src = image.video;
      videoElement.controls = true;
      videoElement.autoplay = true;
      videoElement.style.width = '100%';
      videoElement.style.height = '100%';

      const videoContainer = document.createElement('div');
      videoContainer.style.position = 'fixed';
      videoContainer.style.top = '0';
      videoContainer.style.left = '0';
      videoContainer.style.width = '100%';
      videoContainer.style.height = '100%';
      videoContainer.style.backgroundColor = 'black';
      videoContainer.style.display = 'flex';
      videoContainer.style.alignItems = 'center';
      videoContainer.style.justifyContent = 'center';
      videoContainer.style.zIndex = '1000';

      videoContainer.appendChild(videoElement);
      document.body.appendChild(videoContainer);

      if (videoContainer.requestFullscreen) {
        videoContainer.requestFullscreen();
      } else if (videoContainer.mozRequestFullScreen) {
        videoContainer.mozRequestFullScreen();
      } else if (videoContainer.webkitRequestFullscreen) {
        videoContainer.webkitRequestFullscreen();
      } else if (videoContainer.msRequestFullscreen) {
        videoContainer.msRequestFullscreen();
      }

      videoElement.onended = () => {
        document.body.removeChild(videoContainer);
      };

      videoContainer.onclick = () => {
        document.body.removeChild(videoContainer);
      };
    
    // if (image.video) {
    //   Swal.fire({
    //       title: `${image.name}`,
    //       html: `<video width="100%" controls autoplay>
    //               <source src="${image.video}" type="video/mp4">
    //               Your browser does not support the video tag.
    //              </video>`,
    //       confirmButtonText: 'OK',
    //       allowOutsideClick: false,
    //   });
    } else {
      Swal.fire({
          title: `${image.name}`,
          imageUrl: `${image.image}`,
          text: `${image.name}`,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
      });
  }
  };

  return (
    <Carousel 
      autoPlay 
      infiniteLoop 
      interval={5000} 
      showThumbs={false}
      showStatus={false}
      stopOnHover={true}
      >
      {images.map((image, index) => (
        <div key={index} onClick={() => handleImageClick(image)}>
            <img
              className="d-block w-100"
              src={image.image} 
              alt={`Slide ${index + 1}`} 
              style={{ cursor: 'pointer' }}
            />
        </div>
      ))}
    </Carousel>
  );
};

export default Advertisement;
