import React from 'react';
import MatchCard from '../components/MatchCard';
import Loading from '../components/Loading';
import {useEffect, useState} from 'react';

export default function Matches() {
	const [matches, setMatches] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/match/getMatchesDone`)
		.then(response => response.json())
		.then(result => {
			console.log(result)
			setMatches(
				result.map(match => {
					return (
						<MatchCard key={match._id} match ={match}/>
					)
				})
			)
			setIsLoading(false)
		})
	}, [])

	return(			
			(isLoading) ?
				<Loading/>
			:
			<>
				<h4 className='p-3 text-black text-center'>Match History</h4>
                {matches}
			</>
	)
}
