import React from 'react';
import {Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import UserContext from '../UserContext';
import {useContext} from 'react';

export default function AgentCard({agent}){
  const {user} = useContext(UserContext)

  // const {username, firstName, lastName, email, mobileNo, earnagentdiamond, agentdiamond, subAgentCategory, agentpercentage, _id} = agent.agentProp
  return(
    <>
    <h4 className='text-primary'>{agent.username}</h4>
    <Card className='mb-3'>
      <Card.Body>
        <Card.Title className='text-primary'>Name: {agent.firstName} {agent.lastName}</Card.Title>
        <Card.Text>Email: {agent.email}</Card.Text>
        <Card.Text>Mobile No: {agent.mobileNo}</Card.Text>
        <Card.Text>Loader Level: {agent.subAgentCategory}</Card.Text>
        <Card.Text>Loader Percentage: {agent.agentpercentage} %</Card.Text>
        <Card.Text>Diamonds for Distribution: {agent.agentdiamond}</Card.Text>
        <Card.Text>Diamonds for Playing: {agent.diamond}</Card.Text>
        <Card.Text>Commission Earned: {agent.earnagentdiamond} diamonds</Card.Text>
        <Link className="btn btn-primary" to={`/agent/${user.id}/${agent._id}`}>Transfer Diamonds to Loader</Link>
      </Card.Body>
    </Card>
    </>
  )
}
