import React from 'react';
import '../App.css';
import { Row, Col, Card } from 'react-bootstrap';

export default function Highlights () {

    const potion = '/items/HPpotion.png';
    return (
        <>
        <h4 className='text-black text-center'>Market Place</h4>
        <Row className="mt-4 mb-4">
            <Col xs={12} md={4}>
                <Card className="cardHighlight p-3">
                    <Card.Body>
                        <Card.Title>
                            <h5>Awesome Chicken</h5>
                        </Card.Title>
                        <img 
                            className="container-fluid bg-none" 
                            src="https://fowloffury.s3.ap-southeast-1.amazonaws.com/products/chickens/Chicken10_01.png"
                            alt="Chicken10-01" 
                            border="0" />
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={4} >
                <Card className="cardHighlight p-3">
                    <Card.Body>
                        <Card.Title>
                            <h5>Blue Chicken</h5>
                        </Card.Title>
                        <img className="container-fluid bg-none" src="https://fowloffury.s3.ap-southeast-1.amazonaws.com/products/chickens/Chicken07_01.png" alt="Chicken07-01" border="0" />
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={4}>
                <Card className="cardHighlight p-3">
                    <Card.Body>
                        <Card.Title>
                            <h5>Pretty Chicken</h5>
                        </Card.Title>
                        <img className="container-fluid bg-none" src="https://fowloffury.s3.ap-southeast-1.amazonaws.com/products/chickens/Chicken06_01.png" alt="Chicken06-01" border="0" />
                    </Card.Body>
                </Card>
            </Col>     
        </Row>

        <Row className="mt-4 mb-4">
            <Col xs={12} md={4}>
                <Card className="cardHighlight p-3">
                    <Card.Body>
                        <Card.Title>
                            <h5>Golden Wings</h5>
                        </Card.Title>
                        <img className="container-fluid bg-none" src="/items/goldenwings.png" alt="Golden Wings" border="0" />
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={4}>
                <Card className="cardHighlight p-3">
                    <Card.Body>
                        <Card.Title>
                            <h5>Chicken Wings</h5>
                        </Card.Title>
                        <img className="container-fluid bg-none" src="/items/goldenwings2.png" alt="Chicken Wings" border="0" />
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={4}>
                <Card className="cardHighlight p-3">
                    <Card.Body>
                        <Card.Title>
                            <h5>+5 Player Level Booster</h5>
                        </Card.Title>
                        <img className="container-fluid bg-none" src="/items/HPpotion.png" alt="HP-potion" border="0" />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </>
    )
}