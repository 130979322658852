import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/MainDashboard.css';

export default function Banner() {
       
    return (
        <Row className="banner">
            <Col>
                <h4 className='text-black'>Play Now and Predict</h4>
            </Col>
        </Row>
    )
}