import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {Form, Button} from 'react-bootstrap';
import {useState, useContext, useEffect} from 'react';
import {useNavigate, Navigate, useParams} from 'react-router-dom';
import UserContext from '../UserContext';
import Swal from 'sweetalert2';
import "../styles/Login.css";

export default function Register() {
	const {user} = useContext(UserContext);
	const navigate = useNavigate();
	const { agentId } = useParams();

	const [username, setUsername] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [mobileNo, setMobileNo] = useState('');
	const [email, setEmail] = useState('');
	const [fbMessenger, setFbMessenger] = useState('');
	const [viberNo, setViberNo] = useState('');
	const [whatsappNo, setWhatsappNo] = useState('');
	const [cityTown, setCityTown] = useState('');
	const [parentReferralCode, setParentReferralCode] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [avatar, setAvatar] = useState('');
	const [isActive, setIsActive] = useState(false);
	const [noAgent, setNoAgent] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [isMerchant, setIsMerchant] = useState(false);

	const avatars = [
		'/avatar/avatar1.png',
		'/avatar/avatar2.png',
		'/avatar/avatar3.png',
		'/avatar/avatar4.png',
		'/avatar/avatar5.png',
		'/avatar/avatar6.png',
		'/avatar/avatar7.png',
		'/avatar/avatar8.png',
		'/avatar/avatar9.png',
		// Add more avatar images here
	];

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleAvatarSelection = (selectedAvatar) => {
		setAvatar(selectedAvatar);
	};

	const handleMerchantToggle = () => {
        setIsMerchant(!isMerchant);
    };

	useEffect(() => {
		if (agentId) {
			fetch(`${process.env.REACT_APP_API_URL}/users/register/${agentId}`)
				.then(response => response.json())
				.then(data => setParentReferralCode(data.agentcode))
				.catch(error => console.error('Error fetching agent:', error));
			setNoAgent(true);
		} else {
			setNoAgent(false);
		}
	  }, [agentId]);

	useEffect(() => {
		if((username !== '' && firstName !== '' && lastName !== '' && mobileNo.length === 11 && email !== '' && password !== '' && confirmPassword !== '') && (password === confirmPassword)){
			setIsActive(true)
		} else {
			setIsActive(false)
		}
	}, [username, firstName, lastName, mobileNo, email, fbMessenger, viberNo, whatsappNo, cityTown, password, confirmPassword]);

	const handleSubmit = async e => {
		e.preventDefault();

		let referralCodeExists = true;
        if (parentReferralCode.trim() !== '') {
            referralCodeExists = await validateReferralCode(parentReferralCode);
            if (!referralCodeExists) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Referral code does not exist in the database. Please use a valid referral code.'
                });
                return;
            }
        }

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/users/register`, {
				method: 'POST',
				headers: {
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify({
					username: username,
					firstName: firstName,
					lastName: lastName,
					mobileNo: mobileNo,
					email: email,
					fbMessenger: fbMessenger,
					viberNo: viberNo,
					whatsappNo: whatsappNo,
					cityTown: cityTown,
					parent_referral_code: parentReferralCode,
					password: password,
					avatar: avatar,
					isMerchant: isMerchant
				})
			});

			const data = await response.json();
			if (response.ok) {
				console.log(data.message);
				Swal.fire({
					title: 'Registration successful!',
					icon: 'success',
					text: data.message
				});
				navigate('/login');
			} else {
				console.error('Error registering user:', data.message);
				if (data.message === 'Username already exists') {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'Username already exists in the database. Please use a different username.'
					});
				} else if (data.message === 'Email already exists') {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'Email already exists in the database. Please use a different email.'
					});
				} else if (data.message === 'Referral Code does not exist') {
					// Show alert for duplicate email
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'Code does not exists in the database. Please use a different code.'
					});
				} else {
					// Show error message
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'An error occurred. Please try again later.'
					});
				}
			}
		} catch (error) {
			console.error('Error registering user:', error.message);
			// Show error message
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Something went wrong!'
			});
		}
	};

	const validateReferralCode = async (parentReferralCode) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/validate-referral-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    parent_referral_code: parentReferralCode
                })
            });
            const data = await response.json();
            return response.ok && data.exists; // Return true if referral code exists, false otherwise
        } catch (error) {
            console.error('Error validating referral code:', error.message);
            return false; // Return false if an error occurs
        }
    };

	return (
		(user.id !==null) ?
			<Navigate to= {"/product"} />
		:
		<div className="login-container">
			<div className="login_box">
				<div className='login-header'>
                    <span>Register</span>
					<img
                        width="100%"
                        height="150px"
                        src="/images/Chicken03_01.png"
                        alt="imgChicken"
                    />
                </div>
				<div className='input_box'>
                    <input type="text" className='input-field' id='username' value={username} onChange={(e) => setUsername(e.target.value)} required></input>
                    <label for='username' className='label'>Username *</label>
                    <i className="icon">
                    <ion-icon name="person"></ion-icon>
                    </i>
                </div>
				<div className='input_box'>
                    <input type="text" className='input-field' id='firstName' value={firstName} onChange={(e) => setFirstName(e.target.value)} required></input>
                    <label for='firstName' className='label'>Firstname *</label>
                    <i className="icon">
                    {/* <ion-icon  name="person"></ion-icon> */}
                    </i>
                </div>
				<div className='input_box'>
                    <input type="text" className='input-field' id='lastName' value={lastName} onChange={(e) => setLastName(e.target.value)} required></input>
                    <label for='lastName' className='label'>Lastname *</label>
                    <i className="icon">
                    {/* <ion-icon  name="person"></ion-icon> */}
                    </i>
                </div>
				<div className='input_box'>
                    <input type="text" className='input-field' id='mobileNo' value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} required></input>
                    <label for='mobileNo' className='label'>Mobile Number *</label>
                    <i className="icon">
                    <ion-icon name="call"></ion-icon>
                    </i>
                </div>
				<div className='input_box'>
                    <input type="text" className='input-field' id='email' value={email} onChange={(e) => setEmail(e.target.value)} required></input>
                    <label for='email' className='label'>Email *</label>
                    <i className="icon">
                    <ion-icon name="mail"></ion-icon>
                    </i>
                </div>
				<div className='input_box'>
                    <input type="text" className='input-field' id='fbMessenger' value={fbMessenger} onChange={(e) => setFbMessenger(e.target.value)} required></input>
                    <label for='fbMessenger' className='label'>Facebook Messenger</label>
                    <i className="icon" id='fb'>
                    <ion-icon name="logo-facebook"></ion-icon>
                    </i>
                </div>
				<div className='input_box'>
                    <input type="text" className='input-field' id='viberNo' value={viberNo} onChange={(e) => setViberNo(e.target.value)} required></input>
                    <label for='viberNo' className='label'>Viber Number</label>
                    <i className="icon" id='viber'>
                    <ion-icon  name="call"></ion-icon>
                    </i>
                </div>
				<div className='input_box'>
                    <input type="text" className='input-field' id='whatsappNo' value={whatsappNo} onChange={(e) => setWhatsappNo(e.target.value)} required></input>
                    <label for='whatsappNo' className='label'>WhatsApp Number</label>
                    <i className="icon" id='whatsapp'>
                    <ion-icon name="logo-whatsapp"></ion-icon>
                    </i>
                </div>
				<div className='input_box'>
                    <input type="text" className='input-field' id='cityTown' value={cityTown} onChange={(e) => setCityTown(e.target.value)} required></input>
                    <label for='cityTown' className='label'>City or Town *</label>
                    <i className="icon">
                    <ion-icon name="location"></ion-icon>
                    </i>
                </div>
				<div className='input_box'>
                    <input type="text" className='input-field' id='parentReferralCode' disabled={(noAgent) ? true : false} value={parentReferralCode} onChange={(e) => setParentReferralCode(e.target.value)} required></input>
                    <label for='parentReferralCode' className='label'>Referral Code - Optional</label>
                    <i className="icon">
                    <ion-icon className="icon" name="qr-code"></ion-icon>
                    </i>
                </div>
				<div className='input_box'>
                    <input type={showPassword ?  "text" : "password"} className='input-field' id='password' value={password} onChange={(e) => setPassword(e.target.value)} required></input>
                    <label for='password' className='label'>Password *</label>
                    <i className="icon">
                    <ion-icon className="icon" name="key"></ion-icon>
                    </i>
                </div>
				<p className="text-muted">
                        Never share your password with anyone else.
                </p>
				<div className='input_box'>
                    <input type={showPassword ?  "text" : "password"} className='input-field' id='confirmPassword' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required></input>
                    <label for='confirmPassword' className='label'>Confirm Password</label>
                    <i className="icon">
                    <ion-icon className="icon" name="lock-closed"></ion-icon>
                    </i>
                </div>
				{(password !== confirmPassword) ?
					<p className='notmatch text-danger'>Password do not match!</p>
						:
					<></>
				}
				<div>
                    <Button onClick={togglePasswordVisibility}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                </div>
				<br />
				<div className='merchant'>
					<div className='merchant_checkbox'>
						<input type= 'checkbox' id='ismerchant' checked={isMerchant} onChange={handleMerchantToggle}/>
						&nbsp;&nbsp;
						<label for='ismerchant'>Register as Merchant?</label>
					</div>
				</div>
				<br/>
				<div className="avatar-selection">
					<Form.Label className="text-primary">Choose an Avatar</Form.Label>
						<div className="avatar-options">
							{avatars.map((avatarUrl, index) => (
								<img
									key={index}
									src={avatarUrl}
									alt={`avatar-${index}`}
									className={`avatar ${avatar === avatarUrl ? 'selected' : ''}`}
									onClick={() => handleAvatarSelection(avatarUrl)}
									style={{ borderRadius: '50%', width: '50px', height: '50px', cursor: 'pointer', margin: '5px', border: avatar === avatarUrl ? '2px solid #007bff' : 'none' }}
								/>
							))}
						</div>
				</div>
				<br/>
				<div>
					<button 
						className={(isActive) ? "login-button" : "login-button1"} 
						onClick={e => handleSubmit(e)}
						variant={(isActive) ? "primary" : "danger"}  
						type="submit" 
						id="submitBtn"
						disabled={!isActive}
						>
			            Register
			        </button>
				</div>
			</div>
		</div>
	)
}